<template>
  <div>
    <a-space direction="vertical" size="large">
      <a-form-model layout="inline">
        <a-form-model-item label="客户端" placeholder="请选择客户端">
          <a-select v-model="query.clientId">
            <a-select-option value="enterprise">
              企业端
            </a-select-option>
            <a-select-option value="platform">
              平台端
            </a-select-option>
            <a-select-option value="supplier">
              供应商端
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="refresh">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
      </div>
    </a-space>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductHeader',
  data () {
    return {
      wrapperCol: { span: 14 },
      queryParam: {}
    }
  },
  computed: {
    ...mapGetters('employmentUser/menu', ['query'])
  },
  methods: {
    reset () {
      this.$store.commit('employmentUser/menu/SET_QUERY', {})
    },
    handleAdd () {
      this.$emit('open-form')
    },
    refresh () {
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
